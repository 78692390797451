<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <!-- <v-cascader
          label="社区"
          v-model="relatedLabelIds"
          :options="sectionOps"
          :props="props"
        /> -->
        <v-select
          clearable
          :options="shelfStsOps"
          v-model="searchParam.shelfSts"
          label="当前状态"
        />
        <v-select
          clearable
          :options="priceOps"
          v-model="searchParam.highOrLow"
          label="价格排序"
        />
        <v-input label="商品名称" clearable v-model="searchParam.title" />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="上架"
          type="text"
          v-if="scope.row.shelfSts == 0 || scope.row.shelfSts == 2"
          @click="changeShelfSts(scope.row, 1)"
        />
        <v-button
          text="下架"
          type="text"
          v-if="scope.row.shelfSts == 1"
          @click="changeShelfSts(scope.row, 2)"
        />
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toCancel(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getGoodsListURL,
  delGoodsInfoURL,
  updShelfStsStatusURL,
} from "../api.js";
import { shelfStsMap, setShelfStsOps } from "../map.js";
import { createImgVNode } from "@/utils/utils.js";

export default {
  data() {
    return {
      shelfStsOps: setShelfStsOps(),
      priceOps: [
        {
          value: 0,
          label: "从低到高",
        },
        {
          value: 1,
          label: "从高到低",
        },
      ],
      searchParam: {
        goodsType: 1,
        shelfSts: undefined,
        title: "",
        highOrLow: undefined,
      },
      tableUrl: getGoodsListURL,
      headers: [
        {
          prop: "tenantName",
          label: "社区",
        },
        {
          prop: "imageUrl",
          label: "商品图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "title",
          label: "商品名称",
        },
        {
          prop: "price",
          label: "所需价格",
        },
        // {
        //   prop: "stock",
        //   label: "库存",
        //   formatter: (row, prop) => {
        //     if (row[prop] !== 0 && row[prop] == "") {
        //       return "无限量";
        //     }
        //     return `${row[prop]}`;
        //   },
        // },
        {
          prop: "shelfSts",
          label: "当前状态",
          formatter(row, prop) {
            return shelfStsMap[row[prop]];
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
  },
  methods: {
    toAdd(data) {
      this.$router.push({
        name: "quarterGoodsForm",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "quarterGoodsForm",
        query: { id: data.id },
      });
    },
    toCancel(data) {
      this.$confirm("你确定要删除吗", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${delGoodsInfoURL}`, null, {
              params: {
                id: data.id,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    changeShelfSts(data, shelfSts) {
      let message = "确定要上架" + data.title;
      if (shelfSts == 2) {
        message = "确定要下架" + data.title;
      }

      this.$confirm(message, "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${updShelfStsStatusURL}`, null, {
            params: {
              id: data.id,
              shelfSts: shelfSts,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
